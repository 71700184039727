/* FacilityManager.module.css */

.facilityManagerContainer {
  padding: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.facilityManagerNavigationHeader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 75px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  padding: 20px;
}

.gridItem {
  position: relative;
  width: 300px;
  height: 350px;
  margin: 0 10px;
  border-radius: var(--panel-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  color: var(--text-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #000000;
  transition: transform 0.2s ease-in-out;
}

.gridItem:hover {
  transform: scale(1.05);
}

.gridItem:hover .icon {
  opacity: 1.0;
  animation: bounce 1s;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, -50%);
  }
  40% {
    transform: translate(-50%, calc(-50% - 5px));
  }
  60% {
    transform: translate(-50%, calc(-50% - 3px));
  }
}

.gridItem .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background-size: cover;
  mix-blend-mode: screen;
  margin: auto;
  display: block;
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.gridItem img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.gridItem h3 {
  font-size: 1.3rem;
  text-align: center;
  color: var(--text-color);
  font-family: var(--main-font);
  margin-bottom: 0.1rem;
  margin-top: auto;
}

.gridItem p {
  text-align: center;
  color: var(--text-color);
  font-family: var(--main-font);
  margin-top: 0.2rem;
}

.missionsPageContainer {
  display: flex;
  width: 100%;
  height: 100vh;
}

.sidebar {
  width: 400px;
  padding: 20px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.divider {
  width: 1px;
  background-color: #333;
  height: 100vh;
}

.rowsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: auto;
  overflow-y: auto;
}

.rowItem {
  flex: 0 0 auto; 
  height: 100px; 
  width: 100%;
  margin: 5px 0;
  border-radius: var(--panel-radius);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 15px;
  color: var(--text-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #000000;
  transition: transform 0.2s ease-in-out;
}

.rowItem:hover {
  transform: scale(1.02);
}

.rowItem .itemInfo {
  flex-grow: 1;
  text-align: left;
  padding-left: 20px;
}

.rowItem .itemInfo h4 {
  color: var(--text-color);
  font-family: var(--main-font);
}

.rowItem .itemInfo p {
  color: var(--text-color);
  font-family: var(--main-font);
  margin-top: 0.2rem;
}

.missionsWrapper {
  background: linear-gradient(to top, #080808 10%, #151515 30%, #1e1e1e 100%);
  border-radius: var(--panel-radius);
  padding: 15px;
  display: flex;
  width: 100%;
}

.backButtonOperations {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: var(--panel-radius);
  cursor: pointer;
  transition: background-color 0.3s;
}

.backButtonOperations:hover {
  background-color: transparent;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.paginationButton {
  margin: 0 5px; 
  padding: 5px 10px;
  border: 2px solid transparent; 
  border-radius: var(--panel-radius);
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.paginationButton:hover {
  background-color: #f0f0f0;
}

.paginationButtonActive {
  background-color: var(--joy-palette-primary-solidBg);
  color: #fff;
}

.itemInfo {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.resourceProgress {
  width: 80%;
  margin: 0 auto;
  position: relative;
  margin-top: 16px;
}

.resourceAmount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-2px);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
  z-index: 1;
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.2) 100%);
  z-index: 1;
}

.resourcesContainer {
  display: flex;
  width: 100%;
  height: 100vh;
}

.resourcesWrapper {
  background: linear-gradient(to top, #080808 10%, #151515 30%, #1e1e1e 100%);
  border-radius: var(--panel-radius);
  padding: 15px;
  display: flex;
  width: 100%;
}

.resourcesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 10px;
  padding: 10px;
  width: 100%;
  align-items: start;
}

.facilityContainer {
  display: flex;
  width: 100%;
  height: 100vh;
}

.facilityWrapper {
  background: linear-gradient(to top, #080808 10%, #151515 30%, #1e1e1e 100%);
  border-radius: var(--panel-radius);
  padding: 15px;
  display: flex;
  width: 100%;
}

.facilityGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 10px;
  padding: 10px;
  width: 100%;
}  