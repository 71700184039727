.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  padding: 1.2rem 1rem;
  color: #fff;
  position: relative;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
}
  
.navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  height: 3px;
  background-color: #fff;
  transform: translateX(-50%);
}

.navbarLinks {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 4rem;
}

.navbarLinks a {
  color: #fff;
  text-decoration: none;
  padding: 0 1rem;
}

.navbarSection {
  position: absolute;
  right: 8rem;
}

nav .username {
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}
  
nav .username:hover {
  color: rgb(60, 60, 60);
}