.admin-dashboard {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  color: #fff;
}

.admin-dashboard h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.admin-dashboard .tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.admin-dashboard .tabs button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.admin-dashboard .tabs button.active {
  background-color: #007BFF;
}

.admin-dashboard .tabs button:hover {
  background-color: #555;
}

.admin-dashboard input[type="text"] {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
}

.admin-dashboard button {
  padding: 8px 12px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.admin-dashboard button:hover {
  background-color: #0056b3;
}

.admin-dashboard p {
  color: #ddd;
  font-size: 0.9rem;
}

.admin-dashboard .tab-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.admin-dashboard th, .admin-dashboard td {
  padding: 8px;
  text-align: left;
  border: 1px solid #313131;
}

.admin-dashboard th {
  background-color: #313131;
  font-weight: bold;
}

.admin-dashboard tr:nth-child(even) {
  background-color: #313131;
}

.admin-dashboard tr:hover {
  background-color: #313131;
}

.admin-dashboard .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.admin-dashboard .pagination button {
  padding: 5px 10px;
  cursor: pointer;
}

.admin-dashboard .button-group {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}
