body, html {
    margin: 0;
    padding: 0;
    font-family: 'Space Grotesk', sans-serif;
    height: 100%;
    overflow-x: hidden;
}

.wrapper {
    background: none;
    background: linear-gradient(to bottom, #000000, #636363);
    min-height: 100vh;
    color: white;
}

.hero {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3rem;
    box-sizing: border-box;
    width: 100%;
}

.hero canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.title {
    margin-bottom: 1rem;
    font-size: 5rem;
    color: #FFFFFF;
    z-index: 1;
}

.button-container {
    display: flex;
    margin-top: 2rem;
    gap: 2rem;
    z-index: 1;
}

.section {
    min-height: 40vh;
    background: transparent;
    color: #ffffff;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.section h2 {
    color: #6b0561;
    margin-top: 1rem;
}

.divider-landing-page {
    width: 85%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 auto;
}

.typewriter-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 50px;
    overflow: hidden;
    position: relative;
}

.typewriter {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
}

.typewriter::after {
    content: '|';
    display: inline-block;
    position: absolute;
    right: -4px;
    animation: blink 0.7s infinite;
    font-size: 1em;
    color: #ffffff;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}