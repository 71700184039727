:root {
  --background-color: #000000;
  --panel-background: #303030;
  --panel-radius: 10px;
  --main-font: 'Space Grotesk', sans-serif;
  --header-font-size: 1.5rem;
  --header-line-height: 3px;
  --header-line-width: 95%;
  --text-color: #ffffff;
  --common-color: grey;
  --uncommon-color: blue;
  --rare-color: red;
  --epic-color: purple;
  --legendary-color: yellow;
}

/* Remove attribution tag from research view */
.react-flow__attribution {
  display: none;
}

.app-background {
  background: var(--background-color);
  position: relative;
  width: 100%;
  height: 100%;
}

.page-header-text h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 3rem;
  font-weight: 700;
  margin-top: 2rem;
  font-family: var(--main-font);
}