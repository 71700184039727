.filterSettings {
    position: sticky;
    top: 50px;
    margin-top: 20px;
    width: 25vw;
    background-color: var(--panel-background);
    border-radius: var(--panel-radius);
    padding: 20px;
    margin-left: 20px;
    z-index: 0;
  }
  
  .searchBar {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .accordion {
    background-color: transparent;
    box-shadow: none;
  }
  
  .accordionDetails {
    padding: 0;
  }
  
  .autocompleteGroup {
    margin-top: 20px;
  }
  
  .filterAutocomplete {
    width: 100%;
    margin-bottom: 20px;
  }
  
  @media (max-width: 600px) {
    .filterSettings {
      position: static;
      width: 100%;
      margin-left: 0;
    }
  }
  