.dashboardContainerGrid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  margin-top: -20px;
}

.panel {
  background: linear-gradient(to top, #070707 5%, #181818 95%);
  border-radius: var(--panel-radius);
  overflow: hidden;
  position: relative;
  padding: 15px;
  margin: 20px;
}

.panelHeader {
  font-size: var(--header-font-size);
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
  padding-left: 10px;
}

.panelHeader::after {
  content: '';
  display: block;
  width: var(--header-line-width);
  height: var(--header-line-height);
  background-color: #ffffff;
  margin-top: 1px;
}

.announcementTitle {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 10px;
}

.announcementDate {
  font-size: 0.9em;
  color: #aaaaaa;
  margin-bottom: 15px;
}

.announcementContent {
  font-size: 1.1em;
  line-height: 1.6;
  white-space: pre-line;
}

.announcementAuthor {
  font-size: 0.9em;
  color: #aaaaaa;
  font-style: italic;
  text-align: right;
}

.announcementItem {
  margin-bottom: 30px; 
  padding-bottom: 20px; 
  border-bottom: 2px solid #444; 
}

.announcementItem:last-child {
  border-bottom: none; 
}
