.resources {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
}

.avatarImage {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}

.avatarCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .resources {
    flex-direction: column;
    align-items: center;
  }
}
