.facilityOverviewContainer {
  display: flex;
  width: 100%;
  height: 100vh;
}

.facilityOverviewWrapper {
  background: linear-gradient(to top, #080808 10%, #151515 30%, #1e1e1e 100%);
  border-radius: var(--panel-radius);
  padding: 15px;
  display: flex;
  width: 100%;
}

.facilityOverview {
  width: 100%;
  padding: 20px;
}

.levelWidget {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
}

.levelWidget h2 {
  margin: 0 0 15px 0;
  color: #fff;
}

.levelDisplay {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.levelNumber {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.xpProgress {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.xpBar {
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.xpFill {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  transition: width 0.3s ease;
}

.xpText {
  color: #fff;
  font-size: 14px;
  text-align: center;
} 