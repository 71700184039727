.cardContainer {
    position: relative;
    border-radius: var(--panel-radius);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .modalView {
    width: 300px;
    height: 450px;
  }
  
  .normalView {
    width: 200px;
    height: 300px;
    border: none;
  }
  
  .normalView:hover {
    transform: scale(1.09);
  }
  
  .grayscale {
    filter: grayscale(100%);
    opacity: 0.6;
  }
  
  .grayscale:hover {
    transform: scale(1);
  }
  
  .cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .wearOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .wearMint {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .wearPolished {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .wearWeathered {
    background: rgba(255, 255, 255, 0.4);
  }
  
  .wearBattered {
    background: rgba(255, 255, 255, 0.6);
  }
  
  .wearRuined {
    background: rgba(255, 255, 255, 0.8);
  }
  
  .normalView.rarityCommon:hover {
    box-shadow: 0 0 20px var(--common-color);
  }
  
  .normalView.rarityUncommon:hover {
    box-shadow: 0 0 20px var(--uncommon-color);
  }
  
  .normalView.rarityRare:hover {
    box-shadow: 0 0 20px var(--rare-color);
  }
  
  .normalView.rarityEpic:hover {
    box-shadow: 0 0 20px var(--epic-color);
  }
  
  .normalView.rarityLegendary:hover {
    box-shadow: 0 0 20px var(--legendary-color);
  }
  