.scpCollectionContainer {
  display: flex;
  gap: 10px; 
  align-items: flex-start; 
  flex-wrap: nowrap; 
  padding: 20px;
}

.scpCard .grayscale {
  filter: grayscale(100%);
  opacity: 0.6;
}

.scpCard:hover.grayscale {
  transform: scale(1);
  filter: grayscale(100%);
  opacity: 0.6;
}

.scpCards {
  display: flex;
  width: 100%;
  min-height: 160vh;
  padding: 20px;
}

.scpCardsContainer {
  flex-grow: 1;
  background: linear-gradient(to top, #080808 10%, #151515 30%, #1e1e1e 100%);
  border-radius: var(--panel-radius);
  padding: 30px;
  padding-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.scpCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  justify-items: center;
  padding-bottom: 40px;
}

.scpCardContainer:hover {
  transform: scale(1.05);
}