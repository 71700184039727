.scpModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scpModalContent {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.9);
  border-radius: var(--panel-radius);
  padding: 20px;
  display: flex;
  width: 80%;
  max-width: 1000px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); 
  overflow: hidden;
  color: #ffffff;
  animation: fadeIn 0.3s ease-in-out;
}

.scpModalLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scpCardWithArrows {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scpModalNavigationArrows {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
}

.navButton {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFFFFF;
  background: linear-gradient(135deg, #bb9df7, #462880);
  border: none;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}


.navButton:hover {
  background: linear-gradient(135deg, #927bc1, #664b9c);
}

.navButton:disabled {
  background: #888888; 
  color: #CCCCCC;
  cursor: not-allowed;
}

.scpModalRight {
  flex: 1; 
  display: flex;
  flex-direction: column;
}

.scpNumber {
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: bold;
}

.scpModalTitle {
  font-weight: bold;
}

.scpMotto {
  margin-bottom: 10px;
  font-style: italic;
}

.scpDetail {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .scpModalContent {
    flex-direction: column;
    width: 90%;
  }

  .scpModalRight {
    padding: 10px;
  }

  .scpModalNavigationArrows {
    justify-content: center;
    max-width: 100%;
    gap: 20px;
  }
}
