.profileContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.infoSection {
  background: rgba(40, 40, 40, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  color: #fff;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.5rem;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #fff;
}

.avatarSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.avatarWrapper {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s;
}

.avatarWrapper:hover {
  transform: scale(1.05);
}

.avatar {
  border: 3px solid rgba(255, 255, 255, 0.2);
}

.avatarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.avatarWrapper:hover .avatarOverlay {
  opacity: 1;
}

.avatarEditIcon {
  color: white;
  font-size: 2rem;
}
